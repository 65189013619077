import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.setInstance()
        this.heightToFit = this.heightAtZDepth(this.instance.position.z)
        this.widthToFit = this.widthAtZDepth(this.instance.position.z)
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.aspectRatio, 0.01, 100)
        this.instance.position.set(0, 0, 10)
        this.scene.add(this.instance)
    }

    heightAtZDepth(ZDepth)
    {
        // vertical fov in radians
        const vFOV = this.instance.fov * Math.PI / 180 
        // Math.abs to ensure the result is always positive
        return 2 * Math.tan( vFOV / 2 ) * Math.abs( ZDepth )
    }

    widthAtZDepth(ZDepth)
    {
        const height = this.heightAtZDepth( ZDepth )
        return height * this.instance.aspect
    }

    zDepthToFitWidth(width) 
    {
        const fov = this.instance.fov * Math.PI / 180 
        const fovh = 2 * Math.atan( Math.tan( fov / 2 ) * this.instance.aspect )
        return Math.abs( width / 2 / Math.tan( fovh / 2 ) )
    }

    resize()
    {
        this.instance.aspect = this.sizes.aspectRatio
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        if(this.controls)
        {
            this.controls.update()
        }
    }
}