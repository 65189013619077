import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Video
{
    constructor(imageName, video, videoSize, vertexShader, fragmentShader)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time

        this.vertexShader = vertexShader
        this.fragmentShader = fragmentShader
        this.setGeometry(videoSize)
        this.setTextures(imageName, video)
        this.setMaterial()
        this.setMesh()
    }

    setGeometry(videoSize)
    {
        this.geometry = new THREE.PlaneGeometry(videoSize.width, videoSize.height, 32, 32)
    }

    setTextures(imageName, video)
    {
        if(imageName === '')
        {
            return
        }
        this.textures = []

        this.textures[0] = imageName

        this.video = video
        this.textures[1] = new THREE.VideoTexture(this.video)

        this.textures.needsUpdate = true
    }

    setMaterial()
    {
        this.material = new THREE.ShaderMaterial({
            transparent: true,
            vertexShader: this.vertexShader,
            fragmentShader: this.fragmentShader,
            uniforms: {
                uIndex: { value: 0 },
                uImage: { value: this.textures[0] },
                uVideo: { value: this.textures[1] },
                uIntro: { value: 0 },
                uOpacity: { value: 1 },
                uNegate: { value: false }
            },
            defines: 
            {
                PR: Math.min(window.devicePixelRatio.toFixed(1), 2)
            }
        })
    }

    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.scene.add(this.mesh)
    }

    update()
    {
    }
}