export default [
    {
        name: '0',
        type: 'texture',
        path: 'textures/images/b.jpg'
    },
    {
        name: '1',
        type: 'texture',
        path: 'textures/images/t.jpg'
    },
    {
        name: '2',
        type: 'texture',
        path: 'textures/images/o.jpg'
    },
    {
        name: '3',
        type: 'texture',
        path: 'textures/images/g.jpg'
    },
    {
        name: '4',
        type: 'texture',
        path: 'textures/images/s.jpg'
    }
]