import * as THREE from 'three'
import { gsap, CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Video from './Video.js'
import videoVertexShader from '../Shaders/slider/vertex.glsl'
import videoFragmentShader from '../Shaders/slider/fragment.glsl'
import Raycaster from '../Utils/Raycaster.js'

export default class Dithering
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.isMobile = this.experience.isMobile
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutQuart', '0.25, 1, 0.5, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        // Setup
        this.isIntroDone = false
        this.links = [
            'https://maoi-dev.github.io/bended-slider/',
            'https://maoi-dev.github.io/text-exploration/',
            'https://weareoptimo.com',
            'https://maoi-dev.github.io/grid-exploration/',
            'https://maoi-dev.github.io/image-selector/'
        ]
        this.cameraZ = 3

        // Mouse
        this.mouse = new THREE.Vector2(-1, -1)
        window.addEventListener('mousemove', (e) => { this.onMouseMove(e) })

        // Videos
        this.videos = []
        this.videosMesh = []

        if(this.isMobile)
        {
            this.videoSize = {
                width: this.camera.widthAtZDepth(this.cameraZ) * .7,
                height: this.camera.widthAtZDepth(this.cameraZ) * .7 * (9 / 16)
            }
        }
        else
        {
            this.videoSize = {
                width: this.camera.heightAtZDepth(this.cameraZ) * .7 * (16 / 9),
                height: this.camera.heightAtZDepth(this.cameraZ) * .7
            }
        }

        this.margin = this.videoSize.width + .25
        this.sliderWidth = this.margin * 5
        this.videosPos = [
            {x: - this.margin * 2, z: -.002},
            {x: - this.margin, z: -.001},
            {x: 0, z: 0},
            {x: this.margin, z: -.001},
            {x: this.margin * 2, z: -.002},
        ]
        this.middleVideo = new THREE.Group()
        this.scene.add(this.middleVideo)

        this.camera.instance.position.z = this.camera.zDepthToFitWidth(this.sliderWidth + .25)

        for(let i = 0; i < 5; i++)
        {
            this.videos[i] = new Video(this.resources.items[i], document.querySelector(`#v-${i}`), this.videoSize, videoVertexShader, videoFragmentShader)
            this.videosMesh[i] = this.videos[i].mesh
            this.videos[i].mesh.position.z = this.videosPos[i].z
            this.videos[i].mesh.scale.set(0)
            this.videos[i].material.uniforms.uIndex.value = i

            if(i === 2)
            {
                this.videos[i].material.uniforms.uNegate.value = true
            }
        }

        // Raycaster
        this.raycaster = new Raycaster(this.videosMesh, this.camera.instance)
        this.raycasterCenter = new Raycaster(this.videosMesh, this.camera.instance, new THREE.Vector2(0, 0))

        // Scroll
        this.timer = null
        this.isScrolling = false
        this.scrollTarget = 0
        this.scroll = 0
        this.isMoving = false

        // Keys Event Listener
        window.addEventListener('keydown', (e) =>
        {
            if(this.isIntroDone)
            {
                switch (e.key) 
                {
                    case 'ArrowUp':
                        this.scrollTarget += this.margin;
                        break;
                    case 'ArrowDown':
                        this.scrollTarget -= this.margin;
                        break;
                    case 'ArrowLeft':
                        this.scrollTarget += this.margin;
                        break;
                    case 'ArrowRight':
                        this.scrollTarget -= this.margin;
                        break;
                }
            }
        })

        // Arrows
        if(!this.isMobile)
        {
            this.arrows = document.querySelectorAll('.arrow')

            this.arrows.forEach((arrow, index) =>
            {
                arrow.addEventListener('click', () => 
                {
                    if(index === 0)
                    {
                        this.scrollTarget += this.margin
                    }
                    else
                    {
                        this.scrollTarget -= this.margin
                    }
                })
            })
        }
        else
        {
            this.buttons = document.querySelectorAll('.buttons > div')

            this.buttons.forEach((button, index) =>
            {
                button.addEventListener('touchstart', () => 
                {
                    if(index === 0)
                    {
                        this.scrollTarget += this.margin
                    }
                    else
                    {
                        this.scrollTarget -= this.margin
                    }
                })
            })
        }
    }

    intro()
    {
        this.videos.forEach((video, index) =>
        {
            gsap.to(video.mesh.scale, {
                x: 1,
                y: 1,
                z: 1,
                ease: 'easeOutQuart',
                duration: 1,
                onComplete: () =>
                {
                    gsap.to(video.mesh.position, {
                        x: (index * this.margin + this.scroll + 10000 * this.sliderWidth) % this.sliderWidth - (this.margin * 2),
                        ease: 'easeOutQuart',
                        onComplete: () =>
                        {
                            gsap.to(video.mesh.position, {
                                y: -((index * this.margin + this.scroll + 10000 * this.sliderWidth) % this.sliderWidth - (this.margin * 2)) / 2.5,
                                ease: 'easeOutQuart',
                                onComplete: () =>
                                {
                                    this.isIntroDone = true

                                    gsap.to(this.videos[2].material.uniforms.uIntro, {
                                        value: 1
                                    })

                                    if(video.mesh.position.x !== 0)
                                    {
                                        gsap.to(video.material.uniforms.uOpacity, {
                                            value: .75,
                                            ease: 'easeOutExpo',
                                        })
                                    }

                                    gsap.to(this.camera.instance.position, {
                                        z: this.cameraZ,
                                        ease: 'easeOutExpo',
                                    })

                                    gsap.to('.links li', {
                                        scale: 1,
                                        ease: 'easeOutExpo'
                                    })

                                    gsap.set('.indicator', {
                                        display: 'block'
                                    })

                                    gsap.to('.indicator', {
                                        scale: 1,
                                        padding: '1rem',
                                        ease: 'easeOutExpo'
                                    })

                                    gsap.to('.arrow', {
                                        scale: 1,
                                        ease: 'easeOutExpo'
                                    })

                                    document.querySelector('#v-2').play()
                                    document.querySelector('#v-2').playsinline = true
                                }
                            })
                        }
                    })
                }
            })
        })
    }

    mobileIntro()
    {
        this.camera.instance.position.z = 6

        this.videos.forEach((video, index) =>
        {
            gsap.to(video.mesh.scale, {
                x: 1,
                y: 1,
                z: 1,
                ease: 'easeOutQuart',
                duration: 1,
                onComplete: () =>
                {
                    gsap.to(video.mesh.position, {
                        y: -((index * this.margin + this.scroll + 10000 * this.sliderWidth) % this.sliderWidth - (this.margin * 2)) / 1.5,
                        ease: 'easeOutQuart',
                        onComplete: () =>
                        {
                            this.isIntroDone = true

                            gsap.to(this.videos[2].material.uniforms.uIntro, {
                                value: 1
                            })

                            if(video.mesh.position.y !== 0)
                            {
                                gsap.to(video.material.uniforms.uOpacity, {
                                    value: .75,
                                    ease: 'easeOutExpo',
                                })
                            }

                            gsap.to(this.camera.instance.position, {
                                z: this.cameraZ,
                                ease: 'easeOutExpo',
                            })

                            gsap.to('.links li', {
                                scale: 1,
                                ease: 'easeOutExpo'
                            })

                            gsap.set('.indicator', {
                                display: 'block'
                            })

                            gsap.to('.indicator', {
                                scale: 1,
                                padding: '1rem',
                                ease: 'easeOutExpo'
                            })

                            gsap.to('.arrow', {
                                scale: 1,
                                ease: 'easeOutExpo'
                            })

                            document.querySelector('#v-2').play()
                        }
                    })
                }
            })
        })
    }

    onMouseMove(event) 
    {
        this.mouse.x = ((event.clientX / window.innerWidth) * 2 - 1) * .1
        this.mouse.y = (-(event.clientY / window.innerHeight) * 2 + 1) * .1
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    update()
    {
        if(this.isIntroDone)
        {
            this.scroll = this.lerp(this.scroll, this.scrollTarget, .1)

            if(this.raycasterCenter)
            {
                this.raycasterCenter.update()

                if(this.raycasterCenter.currentIntersect)
                {
                    this.middleVideo.add(this.raycasterCenter.currentIntersect.object)

                    gsap.to(this.raycasterCenter.currentIntersect.object.material.uniforms.uIntro, {
                        value: 1
                    })

                    if(document.querySelector('.indicator').href !== this.links[this.raycasterCenter.currentIntersect.object.material.uniforms.uIndex.value])
                    {
                        document.querySelector('.indicator').href = this.links[this.raycasterCenter.currentIntersect.object.material.uniforms.uIndex.value]
                    }

                    this.videos.forEach(video => 
                    {
                        if(video.mesh.parent && video.mesh !== this.raycasterCenter.currentIntersect.object)
                        {
                            this.middleVideo.remove(video.mesh)
                            this.scene.add(video.mesh)
                            gsap.to(video.material.uniforms.uIntro, {
                                value: 0
                            })
                        }
                    })
                }
            }

            if(this.raycaster)
            {
                this.raycaster.update()

                this.videos.forEach((video, index) => 
                {
                    if(this.raycaster.currentIntersect && this.raycaster.currentIntersect.object.position.x < .5 && this.raycaster.currentIntersect.object.position.x > -.5 && !this.isMobile)
                    {
                        gsap.to(this.middleVideo.position, {
                            x: this.mouse.x,
                            y: this.mouse.y,
                            duration: 1
                        })

                        gsap.to('.indicator', {
                            x: ((this.mouse.x * window.innerWidth) / 2 + 1) * .5,
                            y: (-(this.mouse.y * window.innerHeight) / 2 - 1),
                            padding: '1rem',
                            duration: 1
                        })
                    }
                    else
                    {
                        gsap.to(this.middleVideo.position, {
                            x: 0,
                            y: 0,
                            duration: 1
                        })

                        gsap.to('.indicator', {
                            x: '0',
                            y: '0',
                            padding: '.5rem',
                            duration: 1
                        })
                    }

                    if(this.raycaster.currentIntersect && video.mesh === this.raycaster.currentIntersect.object)
                    {
                        gsap.to(video.material.uniforms.uOpacity, {
                            value: 1
                        })
                    }
                    else if(video.mesh.position.x < .5 && video.mesh.position.x > -.5)
                    {
                        gsap.to(video.material.uniforms.uOpacity, {
                            value: 1
                        })

                        document.querySelector(`#v-${index}`).play()
                    }
                    else
                    {
                        gsap.to(video.material.uniforms.uOpacity, {
                            value: .75
                        })

                        document.querySelector(`#v-${index}`).load()
                        document.querySelector(`#v-${index}`).pause()
                        document.querySelector(`#v-${index}`).currentTime = 0
                    }

                    if(this.raycaster.objectClicked)
                    {
                        if(this.raycaster.objectClicked.object.position.x > 0.5)
                        {
                            this.scrollTarget -= this.margin
                            this.raycaster.objectClicked = null
                        }
                        else if(this.raycaster.objectClicked.object.position.x < -0.5)
                        {
                            this.scrollTarget += this.margin
                            this.raycaster.objectClicked = null
                        }
                    }
                })

                if(this.isMobile)
                {
                    this.videos.forEach((video, index) => 
                    {
                        video.mesh.position.y = -((index * this.margin + this.scroll + 10000 * this.sliderWidth) % this.sliderWidth - (this.margin * 2)) / 1.5
                    })
                }
                else
                {
                    this.videos.forEach((video, index) => 
                    {
                        video.mesh.position.x = (index * this.margin + this.scroll + 10000 * this.sliderWidth) % this.sliderWidth - (this.margin * 2)
                        video.mesh.position.y = -((index * this.margin + this.scroll + 10000 * this.sliderWidth) % this.sliderWidth - (this.margin * 2)) / 2.5
                    })
                }
            }
        }
    }
}