import { gsap, CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Dithering from './Dithering.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.isMobile = this.experience.isMobile
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutQuart', '0.25, 1, 0.5, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        this.resources.on('ready', () =>
        {
            // Setup
            this.dithering = new Dithering()
        })

        this.logoParts = document.querySelectorAll('.logo img')

        gsap.to(this.logoParts, {
            opacity: 1,
            stagger: {
                each: .1
            },
            delay: 1,
            onComplete: () =>
            {
                if(this.isMobile)
                {
                    gsap.to('.logo', {
                        width: '4vh',
                        height: '4vh',
                        top: 'calc((12.5vw - .5rem))',
                        ease: 'easeOutExpo',
                        duration: .5,
                        onComplete: () =>
                        {
                            this.dithering.mobileIntro()

                            document.querySelector('.logo').addEventListener('mouseover', () =>
                            {
                                gsap.to(this.logoParts, {
                                    opacity: 0,
                                    stagger: {
                                        each: -.1
                                    },
                                    onComplete: () =>
                                    {
                                        gsap.to(this.logoParts, {
                                            opacity: 1,
                                            stagger: {
                                                each: .1
                                            }
                                        })
                                    }
                                })
                            })
                        }
                    })
                }
                else
                {
                    gsap.to('.logo', {
                        width: '4vh',
                        height: '4vh',
                        top: 'calc((15vh - .5rem) / 2)',
                        left: 'calc(100vw - (15vh - .5rem) / 2)',
                        ease: 'easeOutExpo',
                        duration: .5,
                        onComplete: () =>
                        {
                            this.dithering.intro()

                            document.querySelector('.logo').addEventListener('mouseover', () =>
                            {
                                gsap.to(this.logoParts, {
                                    opacity: 0,
                                    stagger: {
                                        each: -.1
                                    },
                                    onComplete: () =>
                                    {
                                        gsap.to(this.logoParts, {
                                            opacity: 1,
                                            stagger: {
                                                each: .1
                                            }
                                        })
                                    }
                                })
                            })
                        }
                    })
                }
            }
        })
    }

    update()
    {
        if(this.dithering)
        {
            this.dithering.update()
        }
    }
}